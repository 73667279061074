import React, { useEffect, useMemo } from "react";
import { formatNumber, IconButton, Notification } from "core";
import { useAppDispatch } from "source/redux";
import CloseIcon from "@mui/icons-material/Close";
import { removeSnackbar } from "source/redux/ui";
import { getDocListsBatchCounts } from "source/redux/documentLists";
import { useSelector } from "react-redux";
import { LinearProgress } from "source/components/library/Progress/LinearProgress";

// TODO: Use file size and bytes left to estimate lol
const getMinutesLeft = (total: number, resolved: number) => {
  const remaining = total - resolved;
  const minutes = Math.ceil(remaining / 150);
  return minutes;
};

export const DocListsUploadingStatus = () => {
  const dispatch = useAppDispatch();

  const docListBatchCounts = useSelector(getDocListsBatchCounts);

  const { resolved, total, isComplete } = useMemo(() => {
    const result = Object.values(docListBatchCounts).reduce(
      (acc, curr) => {
        const copy = { ...acc };
        copy.resolved += curr.resolved;
        copy.total += curr.total;
        return copy;
      },
      { resolved: 0, total: 0 }
    );

    return {
      resolved: result.resolved,
      total: result.total,
      isComplete: result.resolved > result.total || !result.total,
    };
  }, [docListBatchCounts]);

  useEffect(() => {
    if (resolved > total || !total) {
      setTimeout(() => {
        dispatch(removeSnackbar("docListsUploadingStatus"));
      }, 2000);
    }
  }, [resolved, total, dispatch]);

  return (
    <Notification
      className="relative"
      closeNotification={() =>
        dispatch(removeSnackbar("docListsUploadingStatus"))
      }
    >
      <div className="flex items-center">
        <div className="flex w-0 flex-1 items-center justify-between">
          <div className="flex flex-col">
            <p className="text-sm font-normal text-gray-900">
              {isComplete
                ? "Upload complete!"
                : `Uploading ${formatNumber(total)} files`}
            </p>
            {!isComplete && (
              <p className="mt-1 text-xs font-normal text-darkGray">
                {`${formatNumber(resolved)}/${formatNumber(total)} • ${getMinutesLeft(total, resolved)} min left`}
              </p>
            )}
          </div>
        </div>
        <div className="ml-4 flex flex-shrink-0">
          <IconButton
            variant="text"
            onClick={() => dispatch(removeSnackbar("docListsUploadingStatus"))}
          >
            <span className="sr-only">Close</span>
            <CloseIcon fontSize="small" aria-hidden="true" />
          </IconButton>
        </div>
      </div>
      <LinearProgress
        singleChunk={total < 5}
        percent={((resolved ?? 0) / (total ?? 0)) * 100}
        className="absolute bottom-0 left-0 right-0 w-full"
      />
    </Notification>
  );
};
