import React, { useCallback } from "react";
import { AlertSelector } from "./AlertSelector";
import { Toast, Buffet } from "packages/core";
import { useSelector } from "react-redux";
import { getToasts, getSnackbarItems, removeToast } from "source/redux/ui";
import { useAppDispatch } from "source/redux";
import { SnackbarSelector } from "./SnackbarSelector";

export const NotificationRegion = () => {
  const dispatch = useAppDispatch();

  const toasts = useSelector(getToasts);
  const snackbarItems = useSelector(getSnackbarItems);

  const removeToastHandler = useCallback(
    (id: string) => {
      dispatch(removeToast(id));
    },
    [dispatch]
  );

  /*
  Global notification live region
  Must be rendered permanently at the end of the document
  Notifications are rendered in reverse order, so prioritize notifications at the top of the children
  Reference: https://tailwindui.com/components/application-ui/overlays/notifications
  */
  return (
    <Buffet>
      <AlertSelector />
      {snackbarItems?.map((snackbarId) => {
        return <SnackbarSelector key={snackbarId} snackbarId={snackbarId} />;
      })}
      {toasts?.map((toast) => {
        return <Toast key={toast.id} onClose={removeToastHandler} {...toast} />;
      })}
    </Buffet>
  );
};
