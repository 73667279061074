import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAlert,
  getMatrixBottomCellsBarVisible,
  setAlert,
} from "source/redux/ui";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TailwindAlert } from "./TailwindAlert";
import { useGetRouter } from "source/hooks/useGetRouter";
import { classNames } from "core";
import { useMatrixAccess } from "source/hooks/matrix/useMatrixAccess";
import { getActiveReport } from "source/redux/matrix";

/**
 * @deprecated Use a Snackbar item instead
 */
export const AlertSelector = () => {
  /*
  Uses Redux state to selectively render an alert, otherwise null
  Must be a child component of NotificationRegion.tsx only.
  Cannot be rendered elsewhere in the component tree due to fixed positioning.
  */

  /*
  An Alert component is a permanent notification that requires user action and cannot
  be closed. This makes it different from a Notification component which is temporary.
  */
  const dispatch = useDispatch();
  const alert = useSelector(getAlert);
  const { router } = useGetRouter();
  const matrixCellBarVisible = useSelector(getMatrixBottomCellsBarVisible);
  const { isViewer } = useMatrixAccess();
  const isGiga = useSelector(getActiveReport)?.is_giga;
  const showBottomMargin = matrixCellBarVisible && !isGiga && !isViewer;

  // clear alert on route change
  useEffect(() => {
    const handleRouteChange = () => {
      dispatch(
        setAlert({
          alert: null,
        })
      );
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, dispatch]);

  if (alert.alert === "websocketDisconnected")
    return (
      <TailwindAlert
        primaryText="Problem connecting"
        secondaryText="Refresh page"
        Icon={<ErrorOutlineIcon sx={{ fontSize: "20px" }} />}
        onClick={() => window.location.reload()}
        styleOverrides={classNames("ml-52", showBottomMargin && "mb-16")}
      />
    );
  return null;
};
