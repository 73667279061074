import React from "react";
import { Button, IconButton, MaterialSymbolsIcon, Notification } from "core";
import { useAppDispatch } from "source/redux";
import CloseIcon from "@mui/icons-material/Close";
import { removeSnackbar } from "source/redux/ui";
import { useRequestMatrixSharing } from "source/hooks/useRequestMatrixSharing";

/**
 * Unlike DocumentNotAccessibleGeneric, this notification should only show up if a doc is not accessible because org sharing is enabled
 * In this case, we show the user a button to request access to the document.
 */
export const DocumentNotAccessibleOrgSharing = () => {
  const dispatch = useAppDispatch();

  const { handleMatrixShareRequest } = useRequestMatrixSharing();

  return (
    <Notification>
      <div className="flex items-center">
        <div className="mr-4">
          <MaterialSymbolsIcon size="2xl" icon="error" />
        </div>
        <div className="flex w-0 flex-1 items-center justify-between">
          <div className="flex flex-col">
            <p className="text-sm font-normal text-gray-900">
              Unable to open document
            </p>
            <p className="mt-1 text-xs font-normal text-darkGray">
              You must be shared on the Matrix to view the documents
            </p>
            <Button
              variant="text"
              className="px-0 pt-3 text-hebbiaBlue hover:bg-transparent"
              onClick={async () => {
                dispatch(removeSnackbar("documentNotAccessibleOrgSharing"));
                await handleMatrixShareRequest();
              }}
            >
              Request Access
            </Button>
          </div>
        </div>
        <div className="ml-4 flex flex-shrink-0">
          <IconButton
            variant="text"
            onClick={() =>
              dispatch(removeSnackbar("documentNotAccessibleOrgSharing"))
            }
          >
            <span className="sr-only">Close</span>
            <CloseIcon fontSize="small" aria-hidden="true" />
          </IconButton>
        </div>
      </div>
    </Notification>
  );
};
