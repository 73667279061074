import React, { useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { classNames } from "core";

type Props = {
  percent: number;
  singleChunk?: boolean;
  className?: string;
};

export const LinearProgress = ({ percent, singleChunk, className }: Props) => {
  const prevPercent = useRef(percent);

  useEffect(() => {
    prevPercent.current = percent;
  }, [percent]);

  const { width } = useSpring({
    from: { width: singleChunk ? 0 : prevPercent.current }, // What percentage of the operation is already complete
    to: { width: singleChunk ? 100 : Math.min(percent, 97) }, // 97% is the maximum width of the progress bar so we don't reach 100% too early
    config: { duration: 2500, easing: (t) => Math.sqrt(t) },
  });

  return (
    <div className={classNames("h-[3px] w-full bg-blue2", className)}>
      <animated.div
        className="h-[3px] bg-hebbiaBlue"
        style={{
          width: width.to((width) => `${width}%`),
        }}
      />
    </div>
  );
};
