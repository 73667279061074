import React from "react";
import { REPORTS_TOPBAR_HEIGHT } from "source/constants";
import { UserDropdown } from "./UserDropdown";
import { OrgDropdown } from "source/components/shared/OrgDropdown";
import { classNames } from "core";
import { API_ENV, LOCAL_ENV, STAGING_ENV } from "source/envConstants";
import { Badge } from "core";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";

type Props = {
  isOnboarded?: boolean;
  searchDisabled?: boolean;
  className?: string;
};

export const SettingsBar = ({
  isOnboarded = true,
  searchDisabled = false,
  className,
}: Props) => {
  const showDevEnvironment = useFeatureFlag("showDevEnvironment");
  return (
    <div
      style={{ height: REPORTS_TOPBAR_HEIGHT }}
      className={classNames(
        "flex h-12 items-center justify-end gap-2 pr-4",
        className
      )}
    >
      {showDevEnvironment && [LOCAL_ENV, STAGING_ENV].includes(API_ENV) && (
        <Badge>{API_ENV.toUpperCase()}</Badge>
      )}
      <OrgDropdown />
      <UserDropdown isOnboarded={isOnboarded} />
    </div>
  );
};
