import { useUser } from "@auth0/nextjs-auth0/client";
import DefaultProfilePic from "assets/icons/profilePicDefault.png";
import { MenuRow } from "source/components/shared/MenuRow";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "source/components/shared/Popover";
import Image from "next/image";
import React from "react";
import { clearAuthToken } from "source/auth/localStorage";
import { StyleSheet } from "source/Types";
import { DropdownProfile } from "./DropdownProfile";
import { useSearchStateManager } from "source/hooks/useSetRouter";
import { useModal } from "source/components/modals/useModal";
import { ModalTypes } from "source/components/modals/modals.types";

type Props = {
  isOnboarded?: boolean;
};

// FOR AUTH ROUTES, DO NOT USE router.push()
export const UserDropdown = ({ isOnboarded = true }: Props) => {
  const { user, isLoading: isLoadingUser } = useUser();
  const { clearUserTargetState } = useSearchStateManager();
  const settingsModal = useModal(ModalTypes.SettingsModal);

  const handleOpenSettings = () => {
    settingsModal.open({});
  };

  const handleLogout = () => {
    goToLogout();
    clearAuthToken();
  };

  const goToLogout = () => {
    clearUserTargetState();
    window.location.href = "/api/auth/logout";
  };

  const goToLoginReturnToSearch = () => {
    // add return to /search ??
    clearUserTargetState();
    window.location.href = "/api/auth/login";
  };

  if (isLoadingUser || !user) {
    return (
      <span
        className="cursor-pointer px-1 pr-4 text-secondary hover:bg-shadow/30"
        onClick={() => goToLoginReturnToSearch()}
      >
        Login
      </span>
    );
  }

  return (
    <div>
      <Popover>
        <PopoverTrigger>
          <Image
            alt="Profile Photo"
            width={24}
            height={24}
            src={user?.picture ? (user.picture as string) : DefaultProfilePic}
            style={styles.profilePic}
          />
        </PopoverTrigger>
        <PopoverContent className="w-[260px] rounded border border-gray-100 py-0.5">
          <DropdownProfile />
          {isOnboarded && [
            <MenuRow
              key="dropdown-settings"
              title="Settings"
              icon="settings"
              onClick={handleOpenSettings}
            />,
          ]}
          <MenuRow
            key="dropdown-logout"
            title="Logout"
            icon="logout"
            onClick={handleLogout}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

const styles: StyleSheet = {
  profilePic: {
    borderRadius: 100,
    height: 24,
    minWidth: 24,
    cursor: "pointer",
    boxShadow: "0px 0px 7px rgba(var(--shadow), .47)",
  },
};
