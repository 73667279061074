import React, { useContext } from "react";
import { Button, IconButton, MaterialSymbolsIcon, Notification } from "core";
import { useAppDispatch } from "source/redux";
import CloseIcon from "@mui/icons-material/Close";
import { removeSnackbar } from "source/redux/ui";
import { useActiveMatrixId } from "source/hooks/matrix/useActiveMatrixId";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";
import {
  calculateCellReviewStats,
  flashAssignedCellReviews,
} from "source/utils/matrix/cellReviews";
import { useQueryCurrentUser } from "source/api/users/useQueryUsers";
import { useQueryCellReviews } from "source/api/matrix/useQueryCellReviews";
import { pluralize } from "source/utils/common/strings";
import { ReportsGridContext } from "source/components/matrix/contexts/ReportsGridContext";

export const AssignedCellReviews = () => {
  const dispatch = useAppDispatch();
  const { gridApi } = useContext(ReportsGridContext);

  const closeNotification = () =>
    dispatch(removeSnackbar("assignedCellReviews"));
  const enableCellReviewers = useFeatureFlag("enableCellReviewers");

  const { data: userData } = useQueryCurrentUser();
  const matrixId = useActiveMatrixId();
  const { data: cellReviews } = useQueryCellReviews(
    matrixId,
    enableCellReviewers
  );

  const { assignedReviews } = calculateCellReviewStats(
    cellReviews,
    userData?.user?.id
  );

  return (
    <Notification
      autoClose
      autoCloseTimeout={20000}
      closeNotification={closeNotification}
    >
      <div className="flex items-center justify-between">
        <div className="mr-2">
          <MaterialSymbolsIcon
            size="xl"
            icon="error"
            className="text-hebbiaBlue"
          />
        </div>
        <div className="flex flex-row items-center">
          <p className="text-sm font-medium text-neutral-800">
            You have been assigned to review {assignedReviews}{" "}
            {pluralize(assignedReviews, "cell")}.
          </p>
          <Button
            variant="text"
            className="ml-3 px-0 pt-2 text-xs font-medium text-hebbiaBlue hover:bg-transparent"
            onClick={() => {
              if (gridApi && cellReviews && matrixId && userData?.user?.id) {
                flashAssignedCellReviews(
                  gridApi,
                  cellReviews,
                  matrixId,
                  userData.user.id
                );
                closeNotification();
              }
            }}
          >
            See Cells
          </Button>
        </div>
        <div className="ml-2 flex flex-shrink-0">
          <IconButton variant="text" onClick={closeNotification}>
            <span className="sr-only">Close</span>
            <CloseIcon fontSize="small" aria-hidden="true" />
          </IconButton>
        </div>
      </div>
    </Notification>
  );
};
