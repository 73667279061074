import { useDispatch, useSelector } from "react-redux";
import api from "source/api";
import { getActiveReportId } from "source/redux/matrix";
import { upsertToast } from "source/redux/ui";
import logger from "source/utils/logger";

/**
 * Handle making a Matrix sharing request
 */
export const useRequestMatrixSharing = () => {
  const dispatch = useDispatch();
  const matrixId = useSelector(getActiveReportId);

  const handleMatrixShareRequest = async () => {
    if (!matrixId) {
      logger.error("No matrix ID to create sharing request for");
      return;
    }

    try {
      await api.reports.createSharingRequest(matrixId);

      // Artificial delay for UI purposes
      setTimeout(() => {
        dispatch(
          upsertToast({
            id: "shareRequestSuccess",
            primaryText: "Requested access from the Matrix owner",
            iconProps: {
              icon: "check_circle",
              style: {
                color: "#4ADE80",
              },
            },
          })
        );
      }, 500);
    } catch (e) {
      logger.error("Failed to send sharing request", {
        error: e,
        matrixId: matrixId,
      });

      // Artificial delay for UI purposes
      setTimeout(() => {
        dispatch(
          upsertToast({
            id: "shareRequestError",
            primaryText: "Error requesting access to the Matrix",
            secondaryText:
              "Please try again later. Contact support@hebbia.ai if the problem persists.",
            icon: "error",
          })
        );
      }, 500);
    }
  };
  return { handleMatrixShareRequest };
};
