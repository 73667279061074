import React from "react";
import { Button, IconButton, MaterialSymbolsIcon, Notification } from "core";
import { useAppDispatch } from "source/redux";
import CloseIcon from "@mui/icons-material/Close";
import { removeSnackbar } from "source/redux/ui";
import { useGetRouter } from "source/hooks/useGetRouter";

export const MatrixAccessError = () => {
  const dispatch = useAppDispatch();

  const { router } = useGetRouter();

  const closeNotification = () => dispatch(removeSnackbar("matrixAccessError"));

  return (
    <Notification
      autoClose
      autoCloseTimeout={10000}
      closeNotification={closeNotification}
    >
      <div className="flex items-center">
        <div className="mr-4">
          <MaterialSymbolsIcon size="2xl" icon="error" />
        </div>
        <div className="flex w-0 flex-1 items-center justify-between">
          <div className="flex flex-col">
            <p className="text-sm font-normal text-gray-900">
              Matrix not accessible
            </p>
            <p className="mt-1 text-xs font-normal text-darkGray">
              You do not have permission to edit this Matrix.
            </p>
            <Button
              variant="text"
              className="px-0 pt-3 text-hebbiaBlue hover:bg-transparent"
              onClick={async () => {
                closeNotification();
                router.push("/matrix");
              }}
            >
              Go Home
            </Button>
          </div>
        </div>
        <div className="ml-4 flex flex-shrink-0">
          <IconButton variant="text" onClick={closeNotification}>
            <span className="sr-only">Close</span>
            <CloseIcon fontSize="small" aria-hidden="true" />
          </IconButton>
        </div>
      </div>
    </Notification>
  );
};
