import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { classNames } from "core";

type Props = {
  primaryText: string;
  secondaryText?: string;
  Icon?: React.ReactNode;
  onClick?: () => void;
  styleOverrides?: string;
};

export const TailwindAlert = ({
  primaryText,
  secondaryText,
  Icon,
  onClick,
  styleOverrides,
}: Props) => (
  /*
  Alert panel.
  Must be a child component of AlertSelector.tsx only.
  Cannot be rendered elsewhere in the component tree due to fixed positioning.
  Reference: https://tailwindui.com/components/application-ui/overlays/notifications
  */
  <Transition
    show={true}
    as={Fragment}
    enter="transform ease-out duration-300 transition"
    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
    leave="transition ease-in duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div
      className={classNames(
        "pointer-events-auto flex flex-row items-center bg-[#1C1C1C] px-3 pl-4 shadow-lg",
        styleOverrides
      )}
    >
      <div
        className={classNames(
          "flex items-center py-0.5",
          secondaryText ? "border-r border-gray-600" : ""
        )}
      >
        {Icon && (
          <div className="flex items-center justify-center text-white">
            {Icon}
          </div>
        )}
        <p
          className={classNames(
            "ml-3 text-sm font-normal leading-8 text-white",
            secondaryText ? "mr-4" : ""
          )}
        >
          {primaryText}
        </p>
      </div>
      {secondaryText && (
        <>
          {onClick ? (
            <button
              type="button"
              className="ml-4 text-sm font-medium text-white hover:opacity-90"
              onClick={onClick}
            >
              {secondaryText}
            </button>
          ) : (
            <div className="ml-4 text-sm font-normal text-white">
              {secondaryText}
            </div>
          )}
        </>
      )}
    </div>
  </Transition>
);
