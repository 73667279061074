import React from "react";
import { DocumentNotAccessibleGeneric } from "./Snackbar/DocumentNotAccessibleGeneric";
import { DocumentNotAccessibleOrgSharing } from "./Snackbar/DocumentNotAccessibleOrgSharing";
import { MatrixAccessError } from "./Snackbar/MatrixAccessError";
import { DocListsUploadingStatus } from "./Snackbar/DocListsUploadingStatus";
import { AssignedCellReviews } from "./Snackbar/AssignedCellReviews";

// Uses Redux state to selectively render a snackbar. Snackbars are special
// and contain stateful and interactible content so behave different than toasty bois.
export const SnackbarSelector = ({ snackbarId }: { snackbarId: string }) => {
  switch (snackbarId) {
    case "documentNotAccessibleGeneric":
      return <DocumentNotAccessibleGeneric />;
    case "documentNotAccessibleOrgSharing":
      return <DocumentNotAccessibleOrgSharing />;
    case "matrixAccessError":
      return <MatrixAccessError />;
    case "docListsUploadingStatus":
      return <DocListsUploadingStatus />;
    case "assignedCellReviews":
      return <AssignedCellReviews />;
    default:
      return null;
  }
};
