import Image from "next/image";
import React from "react";
import { DocumentFailureReasonType, DocumentType } from "source/Types";
import { documentTypeToIconName } from "source/components/shared/Icons/utils";
import { DocIcon } from "source/components/shared/Icons/DocIcon";
import SECIcon from "assets/integrations/sec.png";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LockIcon from "@mui/icons-material/Lock";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import { Tooltip } from "source/components/shared/Tooltip";

type Props = {
  doc: Partial<DocumentType>;
  overrideUnsupported?: boolean;
  styleOverride?: React.CSSProperties;
  onClick?: () => void;
  fontSize?: "small" | "medium" | "large" | "inherit";
  hideErrors?: boolean;
  fontOverride?: string;
  display?: "block" | "flex";
  docFailed?: boolean;
  failure_reason?: DocumentFailureReasonType;
};

export const DocRowIcon = ({
  doc,
  overrideUnsupported,
  styleOverride,
  onClick,
  fontSize,
  hideErrors,
  fontOverride,
  display,
  docFailed,
  failure_reason,
}: Props) => {
  if (doc.type && doc.type === "sec")
    return (
      <Image
        alt="SEC"
        src={SECIcon}
        style={{
          height: 16,
          width: 16,
          minHeight: 16,
          minWidth: 16,
          ...styleOverride,
          ...(fontOverride ? { fontSize: fontOverride } : {}),
        }}
        onClick={onClick}
      />
    );

  if (docFailed && failure_reason) {
    switch (failure_reason) {
      case "Document is password protected":
        return (
          <Tooltip title={failure_reason}>
            <LockIcon
              fontSize="small"
              className="-ml-1 text-secondary"
              style={{ ...styleOverride }}
            />
          </Tooltip>
        );
      case "Document needs OCR":
        return (
          <Tooltip title={failure_reason}>
            <SdCardAlertIcon
              fontSize="small"
              className="-ml-1 text-secondary"
              style={{ ...styleOverride }}
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title={failure_reason}>
            <ErrorOutlineIcon
              fontSize="small"
              className="-ml-1 text-secondary"
              style={{ ...styleOverride }}
            />
          </Tooltip>
        );
    }
  }

  if (doc.failure_reason && !overrideUnsupported && !hideErrors)
    return (
      <Tooltip title={doc.failure_reason || ""}>
        <ErrorOutlineIcon
          fontSize="small"
          className="-ml-1 cursor-pointer text-secondary"
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      </Tooltip>
    );

  return (
    <DocIcon
      iconName={documentTypeToIconName(doc.mime, doc.type)}
      styleOverride={{
        marginRight: "5px",
        marginLeft: "-4px",
        ...styleOverride,
      }}
      fontSize={fontSize ? fontSize : "small"}
      onClick={onClick}
      fontOverride={fontOverride}
      display={display}
    />
  );
};
