import React from "react";
import { IconButton, MaterialSymbolsIcon, Notification } from "core";
import { useAppDispatch } from "source/redux";
import CloseIcon from "@mui/icons-material/Close";
import { removeSnackbar } from "source/redux/ui";

export const DocumentNotAccessibleGeneric = () => {
  const dispatch = useAppDispatch();

  return (
    <Notification>
      <div className="flex items-center">
        <div className="mr-4">
          <MaterialSymbolsIcon size="2xl" icon="error" />
        </div>
        <div className="flex w-0 flex-1 items-center justify-between">
          <div className="flex flex-col">
            <p className="text-sm font-normal text-gray-900">
              Document not accessible
            </p>
            <p className="mt-1 text-xs font-normal text-darkGray">
              This document is not accessible. Contact support@hebbia.ai if you
              think this is an error.
            </p>
          </div>
        </div>
        <div className="ml-4 flex flex-shrink-0">
          <IconButton
            variant="text"
            onClick={() =>
              dispatch(removeSnackbar("documentNotAccessibleGeneric"))
            }
          >
            <span className="sr-only">Close</span>
            <CloseIcon fontSize="small" aria-hidden="true" />
          </IconButton>
        </div>
      </div>
    </Notification>
  );
};
