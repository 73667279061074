import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import SquareIcon from "@mui/icons-material/Square";
import PersonIcon from "@mui/icons-material/Person";
import PsychologyIcon from "@mui/icons-material/Psychology";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";

type Props = {
  title: string;
  icon: string;
  isActive?: boolean;
  onClick: () => void;
  style?: any;
};

export const MenuRow = ({ title, icon, isActive, onClick, style }: Props) => {
  const getIcon = () => {
    switch (icon) {
      case "account_circle":
        return <AccountCircleIcon fontSize="small" />;
      case "notifications":
        return <NotificationsIcon fontSize="small" />;
      case "person_add":
        return <PersonAddIcon fontSize="small" />;
      case "business":
        return <BusinessIcon fontSize="small" />;
      case "groups":
        return <GroupsIcon fontSize="small" />;
      case "swap_horiz":
        return <SwapHorizIcon fontSize="small" />;
      case "history":
        return <HistoryIcon fontSize="small" />;
      case "settings":
        return <SettingsIcon fontSize="small" />;
      case "logout":
        return <LogoutIcon fontSize="small" />;
      case "person":
        return <PersonIcon fontSize="small" />;
      case "psychology":
        return <PsychologyIcon fontSize="small" />;
      case "command":
        return <KeyboardCommandKeyIcon fontSize="small" />;
      case "integrations":
        return (
          <span className="material-symbols-sharp flex items-center text-xl">
            conversion_path
          </span>
        );
      default:
        return <SquareIcon fontSize="small" />;
    }
  };
  return (
    <div
      style={{
        ...(isActive && {
          backgroundColor: "var(--background-hover)",
        }),
        ...style,
      }}
      onClick={onClick}
      className="flex h-8 w-full cursor-pointer select-none items-center px-3 text-body text-secondary hover:bg-hoverRow active:bg-hoverRowActive"
    >
      {getIcon()}
      <span className="overflow-scroll text-ellipsis whitespace-nowrap px-2">
        {title}
      </span>
    </div>
  );
};
