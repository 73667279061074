import React from "react";
import {
  getCurrentOrg,
  getOrgs,
  setCurrentOrg,
} from "source/redux/organization";
import Image from "next/image";
import { useSelector } from "react-redux";
import { classNames } from "core";
import { Popover, PopoverPanel } from "source/components/library/Popover";
import { useDispatch } from "react-redux";
import { SearchBar } from "source/components/gigabar/components/SearchBar";
import { useState } from "react";
import { substringMatchOrgs } from "source/components/gigabar/components/FileSection/components/OrgSwitcher";
import CorporateFareSharpIcon from "@mui/icons-material/CorporateFareSharp";
import { OrgType } from "source/Types";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { useGetRouter } from "source/hooks/useGetRouter";

const ORGS_TO_SHOW_SEARCH_BAR = 5;

export const OrgDropdown = () => {
  const { router } = useGetRouter();
  const { goToMatrixHome } = useGlobalNavigator();

  const dispatch = useDispatch();
  const orgs = useSelector(getOrgs);
  const currentOrg = useSelector(getCurrentOrg);

  const canSwitchOrg = orgs.length > 1;
  const showSearchBar = orgs.length > ORGS_TO_SHOW_SEARCH_BAR;
  const activeReportId = router.query.matrix_id;

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  if (orgs.length < 2) return null;

  const filteredOrgs = searchValue
    ? substringMatchOrgs(searchValue, orgs)
    : orgs;

  const onOrgClick = (org: OrgType) => {
    if (org.id === currentOrg?.id) return;
    dispatch(setCurrentOrg(org));
    if (activeReportId) goToMatrixHome();
  };
  // TODO: Hover group on image of the logo 90% also maxw of 140
  return (
    <Popover
      trigger={
        <div
          className={classNames(
            "mr-1 flex h-[30px] items-center gap-2 truncate rounded-[3px] px-2 py-1.5 text-xs leading-5 text-gray-500 hover:bg-gray-100 active:bg-transparent lg:mr-0",
            canSwitchOrg ? "group cursor-pointer hover:text-lightBlack" : ""
          )}
        >
          {currentOrg?.logo_url ? (
            <div className="relative inline-block h-4 min-h-[16px] w-4 min-w-[16px] group-hover:opacity-90">
              <Image
                src={currentOrg.logo_url}
                alt={`${currentOrg.name}`}
                objectFit="contain"
                fill={true}
              />
            </div>
          ) : (
            <div className="relative inline-block">
              <CorporateFareSharpIcon fontSize="small" />
            </div>
          )}
          <span className="hidden lg:block">{currentOrg?.name}</span>
        </div>
      }
    >
      <PopoverPanel className="absolute left-auto right-0 z-[9999] flex max-w-[400px] flex-col rounded-sm border-[1px] border-solid border-border2 bg-white py-0.5 shadow-md focus:outline-none">
        {showSearchBar && (
          <div className="mt-1 flex w-full flex-col">
            <SearchBar
              value={searchValue}
              searchable="organization"
              setSearchValue={setSearchValue}
            />
          </div>
        )}
        <div className="max-h-[220px] overflow-y-scroll">
          {filteredOrgs.map((org) => {
            return (
              <div
                className="flex h-8 cursor-pointer items-center gap-2 truncate px-3 py-0.5 text-secondary hover:bg-hoverRow active:bg-hoverRowActive"
                key={org.id}
                onClick={() => onOrgClick(org)}
              >
                {org.logo_url ? (
                  <div className="relative inline-block h-5 w-5">
                    <Image
                      src={org.logo_url}
                      alt={`${org.name}`}
                      objectFit="contain"
                      fill={true}
                    />
                  </div>
                ) : (
                  <div className="relative inline-block h-5 w-5">
                    <CorporateFareSharpIcon fontSize="small" />
                  </div>
                )}
                <span className="truncate">{org.name}</span>
              </div>
            );
          })}
        </div>
      </PopoverPanel>
    </Popover>
  );
};
