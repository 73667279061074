import React, { Fragment, ReactNode } from "react";
import { Popover as HeadlessPopover, Transition } from "@headlessui/react";
import { Button, ButtonProps } from "../Button";

export type PopoverProps = {
  trigger: ReactNode;
  children: ReactNode;
  disableOpen?: boolean;
  disableClose?: boolean;
  disableTransition?: boolean;
};

export const Popover = ({
  trigger,
  children,
  disableOpen,
  disableTransition,
}: PopoverProps) => (
  <HeadlessPopover className="relative">
    <>
      <HeadlessPopover.Button
        disabled={disableOpen}
        className="focus:outline-none"
      >
        {trigger}
      </HeadlessPopover.Button>
      {disableTransition ? (
        <>{children}</>
      ) : (
        <Transition
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          {children}
        </Transition>
      )}
    </>
  </HeadlessPopover>
);

type PopoverButtonProps = ButtonProps;

export const PopoverButton = (buttonProps: PopoverButtonProps) => (
  <HeadlessPopover.Button disabled={buttonProps.isDisabled}>
    <Button {...buttonProps} />
  </HeadlessPopover.Button>
);

export const PopoverPanel = HeadlessPopover.Panel;
