import { Switch } from "antd";
import Image from "next/image";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import partywizard from "assets/partywizard.gif";
import { getUser } from "source/redux/user";
import { StyleSheet } from "source/Types";
import { setPM, upsertToast } from "source/redux/ui";
import { useAdminMode } from "source/hooks/useAdminMode";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";

export const DropdownProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isAdmin = user?.platform_role === "admin";
  const [checked, setChecked] = useState(isAdmin);
  const [partyChecked, setPartyChecked] = useState(false);
  const canParty = useFeatureFlag("canParty");

  const { hebbiaAdmin, toggleAdmin } = useAdminMode();

  const handleDisableAdmin = async () => {
    dispatch(
      upsertToast({
        id: "adminModeReload",
        primaryText: `Toggling ${
          user?.platform_role === "admin" ? "user" : "admin"
        } mode, please wait...`,
        icon: "info",
      })
    );
    setChecked(!checked);
    await toggleAdmin();
    dispatch(
      upsertToast({
        id: "adminModeReload",
        primaryText: "Standby, refreshing the page...",
        icon: "info",
      })
    );
    window.location.reload();
  };

  if (!user) return null;

  return (
    <div style={styles.container}>
      {user?.picture && (
        <Image
          alt="Profile Photo"
          height={70}
          width={70}
          src={user.picture}
          style={{ borderRadius: "50%" }}
        />
      )}
      <p style={styles.name}>{user?.name}</p>
      <p style={styles.email}>{user?.email}</p>
      {hebbiaAdmin ? (
        <>
          <div className="flex flex-col items-center">
            <div className="flex flex-row">
              <p style={styles.admin}>Admin</p>
              <AdminSwitchContainer>
                <Switch
                  checked={checked}
                  size={"small"}
                  onChange={handleDisableAdmin}
                />
              </AdminSwitchContainer>
            </div>
          </div>
        </>
      ) : null}
      {hebbiaAdmin && canParty ? (
        <>
          <div className="flex flex-col items-center">
            <div className="flex flex-row">
              <div className="-ml-1 px-1">
                <Image
                  className=""
                  height={20}
                  width={20}
                  src={partywizard}
                  alt="wizard"
                />
              </div>
              <p style={styles.admin}>Party mode</p>
              <AdminSwitchContainer>
                <Switch
                  checked={partyChecked}
                  className="animated"
                  size={"small"}
                  onChange={() => {
                    dispatch(setPM(!partyChecked));
                    setPartyChecked(!partyChecked);
                  }}
                />
              </AdminSwitchContainer>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const AdminSwitchContainer = styled.div`
  .ant-switch-checked {
    background-color: #c00;
  }
  margin-left: 5px;
`;

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "auto",
    padding: 20,
  },
  name: {
    color: "var(--text-primary)",
    lineHeight: "18px",
    marginBottom: 0,
    paddingTop: 5,
    fontWeight: 500,
  },
  email: {
    color: "var(--text-secondary)",
    lineHeight: "16px",
    marginBottom: 0,
    paddingTop: 5,
  },
  admin: {
    color: "#c00",
    lineHeight: "16px",
    marginBottom: 0,
    paddingTop: 5,
  },
  theme: {
    lineHeight: "18px",
    marginBottom: 0,
    marginRight: 5,
  },
};
